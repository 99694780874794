<template>
    <div class="pb-16px pb-md-20px">
        <v-row align="center" class="row--xs">
            <v-col cols="auto">
                <v-btn text class="align-center ml-n4" @click="selectAll">
                    <v-simple-checkbox v-bind="{ ...checkbox_primary }" :value="isAllSelected" class="v-size--large mb-n1" @click="selectAll" />
                    <span class="ml-3"> 전체선택 </span>
                </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-btn v-bind="{ ...btn_secondary, loading }" small :disabled="!selected.length" @click="removeSelected">
                    <span> 선택삭제 </span>
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn v-bind="{ ...btn_secondary, loading }" small :disabled="!carts__outOfStock.length" @click="removeDisabled">
                    <span> 주문불가삭제 </span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import api from "@/api";
import { btn_secondary, checkbox_primary } from "@/assets/variables";

export default {
    props: {
        value: { type: Array, default: () => [] }, // selected

        carts: { type: Array, default: () => [] },
        cartSellerGroups: { type: Array, default: () => [] },
        // loading: false
    },
    data: () => ({
        selected: [],

        loading: false,

        btn_secondary,
        checkbox_primary,
    }),
    computed: {
        isAllSelected() {
            return !this.carts.some(({ _id }) => !this.selected.some((item) => item._id == _id));
        },
        carts__outOfStock() {
            return this.cartSellerGroups.flatMap(({ shippingGroups }) => shippingGroups.flatMap(({ productGroups }) => productGroups.flatMap(({ product, carts }) => (!product?.sells ? carts : []))));
        },
    },
    methods: {
        init() {
            this.$emit("init");
        },
        sync() {
            this.selected = [...this.value];
        },
        emit() {
            this.$emit("input", this.selected);
        },
        syncLoading() {
            this.loading = this.$attrs.loading;
        },
        emitLoading(loading = this.loading) {
            this.$emit("emitLoading", loading);
        },

        selectAll() {
            if (this.isAllSelected) this.selected = [];
            else this.selected = [...this.carts];

            this.emit();
        },

        async removeSelected() {
            if (this.loading) return;
            else this.loading = true;
            try {
                for (const cart of this.selected) {
                    await api.v1.me.carts.delete(cart);
                }
            } finally {
                this.loading = false;
                this.init();
            }
        },

        async removeDisabled() {
            if (this.loading) return;
            else this.loading = true;

            try {
                for (const cart of this.carts__outOfStock) {
                    await api.v1.me.carts.delete(cart);
                }
            } finally {
                this.loading = false;
                this.init();
            }
        },
    },
    watch: {
        value() {
            this.sync();
        },
        "$attrs.loading"() {
            this.syncLoading();
        },
        loading() {
            this.emitLoading();
        },
    },
    mounted() {
        this.sync();
        this.syncLoading();
    },
};
</script>

<style></style>
