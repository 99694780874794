<template>
    <v-sheet>
        <template v-if="carts.length">
            <v-row>
                <v-col>
                    <view-table
                        v-model="selected"
                        v-bind="{
                            carts,
                            loading,
                            cartSellerGroups,
                        }"
                        v-on="{
                            init,
                            search,
                        }"
                    />
                </v-col>
                <v-col cols="12" xl="3">
                    <view-total
                        v-bind="{
                            selected,
                            loading,
                        }"
                        v-on="{
                            order,
                        }"
                    />
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <div class="text-center">
                <div class="tit tit--sm font-weight-light">장바구니에 담긴 상품이 없습니다.</div>

                <div class="btn-wrap btn-wrap--lg">
                    <v-row justify="center" class="row--sm">
                        <v-col cols="6" sm="auto">
                            <v-btn v-bind="{ ...btn_secondary, loading }" to="/" class="v-size--xx-large w-100 min-w-sm-200px">홈으로 가기</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </template>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { mapMutations, mapState } from "vuex";
import { btn_primary, btn_secondary } from "@/assets/variables";

import ViewTable from "./view/view-table.vue";
import ViewTotal from "./view/view-total.vue";

export default {
    components: {
        ViewTable,
        ViewTotal,
    },
    data: () => ({
        carts: [],
        selected: [],

        loading: false,

        btn_primary,
        btn_secondary,
    }),
    computed: {
        ...mapState(["accessToken"]),
        cartSellerGroups() {
            return this.$getCartSellerGroups(this.carts);
        },
    },
    methods: {
        ...mapMutations(["setCarts"]),

        async init() {
            this.selected = [];
            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const _carts = (this.$store?.state?.carts || []).map(({ _id }) => _id);
                this.carts = (await api.v1.me.carts.gets({ params: { _carts } }))?.carts?.filter?.(({ product }) => product);
                this.setCarts({ carts: this.carts });
            } finally {
                this.loading = false;
                this.selected = [...this.cartSellerGroups.flatMap(({ shippingGroups }) => shippingGroups.flatMap(({ productGroups }) => productGroups.flatMap(({ carts }) => carts)))];
            }
        },

        order(selected = []) {
            try {
                if (!selected.length) throw new Error("구매하실 상품을 선택해주세요");

                const someShippingOutdated = selected.some(({ isShippingOutdated }) => isShippingOutdated);
                if (someShippingOutdated) throw new Error("만료된 배송정책이 있습니다.");

                // const someOptionsOutdated = selected.some(({ _option, product }) => !product._options.includes(_option));
                // if (someOptionsOutdated) throw new Error("만료된 상품옵션이 있습니다.");

                const _carts = selected.map(({ _id }) => _id);
                this.$store.dispatch("pickup", _carts);

                this.$router.push("/shop/order");
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    watch: {
        accessToken() {
            if (this.accessToken) this.init();
        },
    },
    mounted() {
        this.init();
    },
};
</script>
